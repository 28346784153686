body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --header-height: 80px;
  --brand-color-1: rebeccapurple;
  --roboto: 'Roboto', sans-serif;

}

body {
  font-family: var(--roboto);

}

.main-button {
  padding: 6px 18px;
  background-color: var(--brand-color-1);
  color: #fff;
  border: 1px solid var(--brand-color-1);
  box-shadow: none;
  border-radius: 20px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

}

.main-button:hover {
  opacity: 0.88;
  color: #fff;
}

.section-desc {
  letter-spacing: 0.5px;
  line-height: 1.6;
}

.section-header {
  letter-spacing: 0.6px;
  line-height: 1.2;
  font-size: 35px;


}


@media (max-width: 992px) {
  .section-desc {
    font-size: 14px;
  }

  .section-header {
    font-size: 24px;
  }

}