.footer-container {
    padding: 100px 0 0;

    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-image: url('../../Assets/Images/trafficIllustrations.jpg');

    .footer-inner {
        display: flex;
        flex-wrap: wrap;
        column-gap: 50px;
        align-items: center;
        padding-bottom: 100px;

        .footer-left {
            width: 40%;

            @media (max-width:992px) {
                width: 60%;
            }

            @media (max-width:768px) {
                width: 100%;
            }

            .footer-logo {
                img {
                    width: 200px;
                }
            }
            .section-header {
                color: #000;
            }

            .section-desc {
                color: #000;
            }

            .footer-buttons {
                display: flex;
                align-items: center;
                column-gap: 30px;
                margin-top: 30px;
            }
        }
    }
    .footer-bottom {
        padding: 15px 0;
        background: rebeccapurple;
    }

    .footer-links {
        display: flex;
        column-gap: 10px;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 35px;
            border-radius: 50%;
            text-decoration: none;
            background-color: var(--brand-color-1);
            color: #fff;
            transition: all 500ms ease;

            svg {}

            &:hover {
                transform: translateY(-10px);
            }
        }
    }

    .footer-bottom-text {
        text-align: center;
        color: #fff;
    }
}