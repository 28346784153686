.landing-hero-container {
    background-image: url('../../Assets/Images/smallIllustrations.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    // background-attachment: fixed;
    position: fixed;
    z-index: -1;
    color: #000;
    top: 0;
    width: 100%;

    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #6633995c;
        z-index: -1;
    }

    .landing-hero-inner {
        height: calc(100vh - var(--header-height));
        display: flex;
        justify-content: center;
        flex-direction: column;

        .landing-hero-text {
            width: 45%;

            @media (max-width:992px) {
                width: 60%;
            }

            @media (max-width:768px) {
                width: 100%;
            }

            h1 {
                font-size: 50px;
                text-transform: uppercase;

                @media (max-width: 992px) {
                    font-size: 38px;

                }

                @media (max-width: 768px) {
                    font-size: 28px;
                    text-align: center;

                }
            }

            p {
                margin-bottom: 30px;
                font-size: 24px;
                letter-spacing: 0.3px;
                line-height: 1.3;

                @media (max-width: 992px) {
                    font-size: 18px;

                }

                @media (max-width: 768px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }

        .landing-hero-buttons {
            display: flex;
            column-gap: 30px;
            @media (max-width: 768px) {
                justify-content: center;
            }
            a {
                img {}
            }
        }
    }
}