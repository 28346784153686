.landing-services-container {
    padding: 100px 0;
    color: #000;
    background-image: url('../../Assets/Images/largeIllustrations2.jpg');
    background-position: 80% center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    background-attachment: fixed;

    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #6633997a;
        z-index: -1;
    }


    .landing-services-inner {


        .section-header {
            text-align: center;
        }

        .section-desc {
            text-align: center;
            padding: 0 200px;
            @media (max-width:992px) {
                padding: 0 100px;
            }
            @media (max-width:992px) {
                padding: 0 0px;
            }
        }

        .landing-services-boxes {
            display: flex;
            column-gap: 45px;
            row-gap: 30px;
            flex-wrap: wrap;
            width: 55%;
            margin: auto;
            margin-top: 50px;
            justify-content: center;

            @media (max-width: 992px) {
                width: 80%;

            }

            @media (max-width: 768px) {
                width: 100%;

            }
            .service-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 150px;
                height: 150px;
                border: 1px solid #000;
                border-radius: 50%;
                transition: all 500ms ease;
                cursor: pointer;
                @media (max-width: 992px) {
                    width: 100px;
                    height: 100px;    
                }
    
                @media (max-width: 768px) {
                    width: 100px;
                    height: 100px;    
                }
                &:hover{
                    background-color: var(--brand-color-1);
                    color: #fff;
                }
                .service-image {
                    img {
                        @media (max-width: 992px) {
                            width: 80px;
                            height: 80px;    
                        }
            
                        @media (max-width: 768px) {
                            width: 40px;
                            height: 40px;    
                        }
                    }
                }

                .service-text {
                    font-weight: bold;
                    margin-top: 5px;
                    @media (max-width: 992px) {
                         
                    }
        
                    @media (max-width: 768px) {
                       font-size: 14px; 
                    }
                }
            }

        }

    }

}