.header-container {
    height: var(--header-height);
    // box-shadow: 0px 0px 4px 2px #ccc;
    margin-bottom: 5px;
    background-color: #fff;

    .header-inner {
        height: var(--header-height);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            height: var(--header-height);

            img {
                width: 100px;
                height: 100%;
                object-fit: contain;
                position: relative;
                left: -35px;

                @media (max-width: 762px) {
                    width: 80px;
                    left: -25px;

                }
            }
        }

        .nav {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 30px;

            @media (max-width: 992px) {
                display: none;

            }

            .nav-item {
                text-decoration: none;
                color: #000;
                transition: all 500ms ease;
                letter-spacing: 0.6px;
                line-height: 1.6;

                &:hover {
                    color: var(--brand-color-1);
                }

            }
        }

        .mobile-nav {
            display: none;

            @media (max-width: 992px) {
                display: flex;

            }

            .mobile-nav-icon {
                font-size: 18px;
            }

            .mobile-nav-body {
                position: fixed;
                height: 100vh;
                width: 100vw;
                background-color: var(--brand-color-1);
                color: #fff;
                top: 0;
                left: 0;
                transition: all 500ms ease;
                display: flex;
                flex-direction: column;
                padding: 30px 20px;

                .mobile-cross {
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    color: #fff;
                    font-size: 18px;
                }

                .nav-item {
                    text-decoration: none;
                    color: #fff;
                    transition: all 500ms ease;
                    letter-spacing: 0.6px;
                    line-height: 1.6;
                    font-size: 14px;
                    margin-bottom: 8px;

                    &:hover {
                        opacity: 0.8;
                    }

                }
            }

            .mobile-nav-on {
                opacity: 1;
                z-index: 5;

            }

            .mobile-nav-off {
                opacity: 0;
                z-index: -1;

            }

        }


    }
}