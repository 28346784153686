.landing-about-container {
    padding: 50px 0;
    margin-top: calc(100vh);
    // background-color: var(--brand-color-1);
    color: #000;
    background-color: #fff;
    box-shadow: 0px 0px 4px 1px #00000043;

    .landing-about-outer {
        display: flex;
        column-gap: 30px;
        flex-wrap: wrap;

        .landing-about-image {
            flex-basis: 50%;

            @media (max-width:992px) {
                flex-basis: 45%;

            }

            @media (max-width:768px) {
                flex-basis: 100%;

            }

            img {
                width: 100%;
                height: 400px;
                object-fit: contain;
            }
        }

        .landing-about-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-basis: 45%;
            overflow: hidden;

            @media (max-width:992px) {
                flex-basis: 50%;

            }

            @media (max-width:768px) {
                flex-basis: 100%;
                text-align: center;
                margin-top: 30px;

            }

            .landing-about-text {
                display: flex;
                flex-direction: column;
                width: 100%;

                @media (max-width: 768px) {
                    width: 100%;

                }

                .section-header {}
                .section-desc {}
            }
            .landing-about-boxes {
                margin-top: 10px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                column-gap: 15px;
                @media (max-width: 768px) {
                    justify-content: center;
                }

                @media (max-width: 448px) {}

                .about-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--brand-color-1);
                    padding: 10px 10px;
                    border-radius: 20px;
                    height: 120px;
                    width: 120px;
                    @media (max-width:992px) {
                        width: 110px;
                        height: 110px;
                    }
                    @media (max-width:768px) {
                        width: 110px;
                        height: 110px;
                    }

                    @media (max-width:568px) {
                        width: 100px;
                        height: 100px;
                    }

                    .about-box-icon {
                        font-size: 18px;
                        height: 50px;
                        width: 50px;
                        background-color: var(--brand-color-1);
                        color: #fff;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 10px;
                        @media (max-width:992px) {
                            height: 45px;
                            width: 45px;
                            font-size: 24px;
                         }
                        @media (max-width:768px) {
                            height: 45px;
                            width: 45px;
                            font-size: 24px;
                        }
    
                        @media (max-width:568px) {
                           height: 40px;
                           width: 40px;
                           font-size: 14px;
                        }
                    }

                    .about-box-title {
                        text-align: center;
                        font-size: 13px;
                        font-weight: 700;
                        color: #000;
                        text-transform: uppercase;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        @media (max-width:768px) {
                           
                        }
    
                        @media (max-width:568px) {
                           height: 30px;
                          
                        }
                    }
                }

            }

        }
    }

}